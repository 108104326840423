import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Container from "../components/shared/Container"
import SEO from "../components/seo"
import MyIcon from "../components/MyIcon"
import { device } from "../utils/device"

const AboutPage = () => {
  return (
    <Layout breadCrumb={{to: '/about', text: `About`}}>
      <SEO title="About" />
      <Container>
        <Wrapper>
          <IconWrapper>
            <MyIcon />
          </IconWrapper>
          <MainWrapper>
            <Headline>
              Profile
            </Headline>
            <Name>大池祐介</Name>
            <Label>
              Designer / Web Engineer
            </Label>
            <Content>
              <p>
                1983年 東京生まれ。<br />
                武蔵野美術大学大学院デザイン専攻映像コース卒。<br />
                静岡県島田市在住。<br />
                在学中からにがウーロンという団体で主に演劇、映像、などを制作し活動。(現在は活動停止中)<br />
                2014 年から結婚を気に静岡県島田市に移住（婿養子になり大池祐介へ）個人でのデザイン業務や、<br />
                フリーランスとして、東京の事業会社のWebエンジニアなどを経て、現在に至る。
              </p>
            </Content>
            <Headline>
              Service
            </Headline>
            <Content>
              <ul>
                <li>Webサイトのデザイン・実装</li>
                <li>Webサービスの保守・サポート</li>
                <li>広告の企画・制作・立案</li>
                <li>グラフィック全般の企画・制作・立案</li>
                <li>動画制作・立案</li>
              </ul>
            </Content>
            <Headline>
              Office
            </Headline>
            <Content>
              大池祐介デザイン事務所<br />
              427-0038<br />
              静岡県島田市稲荷2-8-8 （ヨハク社内）
            </Content>
          </MainWrapper>
        </Wrapper>
      </Container>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`

const IconWrapper = styled.div`
  width: 128px;
`

const MainWrapper = styled.main`
`

const Headline = styled.h2`
  margin-bottom: 32px;
`


const Label = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`

const Name = styled.h2`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
`

const Content = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  line-height: 1.6;
  font-size: 14px;
  ul {
    list-style: disc;
    margin-left: 21px;
  }
`

export default AboutPage